import casting_calls_services from '@/services/casting_calls';
import pitch_service from '@/services/pitch_service'
import { getUserData } from '@/libs/utils/user';

export default [
  {
    path: '/terminos-y-condiciones',
    name: 'terms-conditions',
    component: () => import('@/views/pages/authentication/Terms-and-condition.vue'),
    meta: {
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Dashboard.vue'),
    meta: {
      resource: 'all',
      action: 'read'
    }
  },
  {
    path: '/campaigns/',
    name: 'campaigns',
    component: () => import('@/views/campaings/CampaignV2.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    }
  },
  {
    path: '/my-work-brand/:section',
    name: 'my_work_brands',
    component: () => import('@/views/pages/casting-calls/my-work/myWorkBrands.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    }
  },
  {
    path: '/brandme-AI/:section?/:category?/:uuid?',
    name: 'brandme_AI',
    component: () => import('@/views/brandme-ai/brandMeAI.vue'),
    meta: {
      resource: 'Auth'
    },
    beforeEnter: (to, from, next) => {
      const has_session = localStorage.getItem('accessToken') !== null;
      if (has_session) {
        next()
      } else {
        const redirect = {
          'name': to.name,
          'params': to.params
        }

        next({ name: 'force-login', params: {redirect}})
      }
    },
  },
  {
    path: '/opportunities/:slug/pitches',
    name: 'pitchs_applied',
    component: () => import('@/views/pages/casting-calls/my-work/opportunities.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    },
    beforeEnter: (to, from, next) => {
      const slug = to.params.slug;
      casting_calls_services.getCastingCallBySlug(slug).then((response) => {
        if (getUserData().id === response.user) {
          to.params.casting_call_data = response;
          next()
        } else {
          next({ name: 'error-404' });
        }
      }).catch(() => {
        next({ name: 'error-404' });
      });
    },
  },
  {
    path: '/step-casting-call/:step/:slug?/',
    name: 'steps_casting_call',
    component: () => import('@/views/pages/casting-calls/stepper/dashboard.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    },
    beforeEnter: (to, from, next) => {
      const slug = to.params.slug;
      if (slug) {
        casting_calls_services.getCastingCallBySlug(slug).then((response) => {
          if (getUserData().id === response.user) {
            to.params.casting_call_data = response;
            next()
          } else {
            next({ name: 'error-404' });
          }
        }).catch(() => {
          next({ name: 'error-404' });
        });
      } else next()
    },
  },
  {
    path: '/pitch/@:slug/step/:step/:pitch_uuid?/',
    name: 'stepper-send-pitch',
    component: () => import('@/views/pages/casting-calls/pitch-stepper/dashboard.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    },
    beforeEnter: (to, from, next) => {
      const pitch_uuid = to.params.pitch_uuid;
      if (pitch_uuid) {
        pitch_service.pitchDetail(pitch_uuid).then((pitchResponse) => {
          if (pitchResponse && pitchResponse.user.id === getUserData().id) {
            to.params.pitch = pitchResponse;
            next();
          } else {
            next({ name: 'error-404' });
          }
        }).catch(() => {
          next({ name: 'error-404' });
        });
      } else {
        next();
      }
    },
  },
  {
    path: '/lists',
    name: 'lists',
    component: () => import('@/views/pages/lists/Lists.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    }
  },
  {
    path: '/realty/campaign/:uuid',
    name: 'realty-campaign',
    component: () => import('@/views/pages/outofhome/Campaign.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    }
  },
  {
    path: '/realty/maps',
    name: 'realty_maps',
    component: () => import('@/views/pages/outofhome/maps/maps.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    }
  },
  {
    path: '/campaign/:uuid',
    name: 'campaign',
    component: () => import('@/views/campaings/campaign.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    }
  },
  {
    path: '/tracking',
    name: 'tracking_links',
    component: () => import('@/views/pages/tracking-links/trackingLinks.vue'),
    meta: {
      action: 'read'
    }
  },
  {
    path: '/tracking/:tracking_uuid',
    name: 'detail_tracking_links',
    component: () => import('@/views/pages/tracking-links/detailTrackingLinks.vue'),
    meta: {
      action: 'read'
    }
  },
  {
    path: '/lead/:slug',
    name: 'campaign-leads',
    component: () => import('@/views/campaings/CampaignLead.vue'),
    meta: {
      resource: 'Auth'
    }
  },
  {
    path: '/brief/:uuid',
    name: 'brief',
    component: () => import('@/views/campaings/brief.vue')
  },
  {
    path: '/requirements/:uuid',
    name: 'requirements-netowrks',
    component: () => import('@/views/campaings/requirementsView.vue')
  },
  {
    path: '/campaignWhite',
    name: 'campaignWhite',
    component: () => import('@/views/campaings/campaignWhite.vue')
  },
  {
    path: '/roll',
    name: 'auth-roll',
    component: () => import('@/views/pages/authentication/Roll.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/search',
    name: 'auth-search',
    component: () => import('@/views/buscador/Searcher.vue'),
    meta: {
      resource: 'view_searcher',
      action: 'manage'
    }
  },
  {
    path: '/realty/search',
    name: 'realty-search',
    component: () => import('@/views/pages/outofhome/buscador.vue'),
    meta: {
      resource: 'view_searcher',
      action: 'manage'
    }
  },
  {
    path: '/perfil/:network/:alias',
    name: 'auth-perfil',
    component: () => import('@/views/pages/profile/Profile.vue'),
    meta: {
      resource: 'Auth',
    }
  },
  {
    path: '/perfil-new/:network/:alias',
    name: 'auth-new-perfil',
    component: () => import('@/views/pages/profile-new/ProfileNew.vue'),
    meta: {
      resource: 'Auth',
    }
  },
  {
    path: '/media-kits',
    name: 'media-kits',
    component: () => import('@/views/media-kits/MediaKits.vue')
  },
  {
    path: '/resumen/perfil/:network/:alias',
    name: 'auth-perfil-resume',
    component: () => import('@/views/pages/profile/summary.vue'),
    meta: {
      layout: 'full',
      resource: 'view_profile',
      action: 'manage'
    }
  },
  {
    path: '/proposal/chat/:chat_uuid?',
    name: 'chat-proposal',
    component: () => import('@/views/pages/proposals/chat/dashboard.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/setting-profile/:section/:is_edit?',
    name: 'setting-public-profile',
    component: () => import('@/views/components/setting-public-profile/dashboard'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/analyzer',
    name: 'analyzer',
    component: () => import('@/views/pages/analyzer/dashboard.vue'),
    meta: {
      resource: 'view_analyzer',
      action: 'manage'
    }
  },
]
